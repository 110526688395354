import PropTypes from 'prop-types';
import {
  useMemo,
  useRef,
  useState,
 } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from '@material-table/core';

import { useConfirm } from 'material-ui-confirm';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  deleteApiKey,
  fetchApiKeys,
  patchActionAlert,
} from 'store/actions/organizations';
import { setSnackbarSuccess } from 'store/actions/global';

import { colors } from 'themes';
import Button from 'common/components/Button';
import Chip from 'common/components/Chip';

import ApiKeyForm from '../ApiKeyForm';

const isEven = number => number % 2 === 0;

const ApiKeysTable = ({
  apiKeysData,
  organizationUuid,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [
    apiKeyDataToEdit,
    setApiKeyDataToEdit,
  ] = useState({
    isDialogOpen: false,
  });
  const tableRef = useRef(null);

  const handleDeleteApiKey = uuid => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected API Key',
      title: 'Delete selected API Key',
    })
      .then(() => dispatch(deleteApiKey(organizationUuid, uuid)))
      .then(() => dispatch(fetchApiKeys(organizationUuid)));
  };

  const onEditClick = apiKeyData => {
    setApiKeyDataToEdit({
      isDialogOpen: true,
      ...apiKeyData,
    });
  };

  const handleUpdateApiKey = async event => {
    const params = {
      isActive: event.isActive,
      name: event.name,
    };

    await dispatch(patchActionAlert(organizationUuid, event.uuid, params));
    await dispatch(fetchApiKeys(organizationUuid));

    setApiKeyDataToEdit({ isDialogOpen: false });
    dispatch(setSnackbarSuccess({ message: 'Correctly updated api key data' }));
  };

  const apiKeysColumns = useMemo(() => [
    {
      cellStyle: {
        fontWeight: '500',
      },
      field: 'name',
      title: 'Name',
      width: '25%',
    },
    {
      field: 'status',
      render: rowData => (
        <Chip
          label={rowData?.isInvited ? 'Inactive' : 'Live'}
          size="medium"
          color={rowData?.isInvited ? 'error' : 'green'}
        />
      ),
      title: 'Status',
    },
    {
      field: '',
      render: rowData => (
        <Button
          disableElevation
          onClick={() => onEditClick(rowData)}
          endIcon={<EditIcon />}
          color="grey"
        >
          Edit
        </Button>
      ),
      title: '',
    },
    {
      align: 'right',
      field: '',
      render: rowData => (
        <Button
          aria-label="delete"
          onClick={() => handleDeleteApiKey(rowData.uuid)}
          size="large"
          endIcon={<DeleteOutlineIcon />}
          color="grey"
        >
          Remove
        </Button>
      ),
      title: '',
    },
  ], []);

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        key="key"
        columns={apiKeysColumns}
        data={apiKeysData}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            color: colors.osloGray,
          },
          maxColumnSort: 0,
          pageSize: 10,
          pageSizeOptions: [
            5,
            10,
            20,
          ],
          rowStyle: (_, rowNumber) => ({
            backgroundColor: isEven(rowNumber) ? '#F8F8F8' : '#FFF',
            fontSize: '14px',
          }),
          search: false,
          showFirstLastPageButtons: false,
          showTitle: false,
        }}
        style={{
          boxShadow: 'none',
        }}
      />
      <ApiKeyForm
        data={apiKeyDataToEdit}
        handleDialogClose={() => setApiKeyDataToEdit({ isDialogOpen: false })}
        onSubmit={handleUpdateApiKey}
      />
    </>
  );
};

ApiKeysTable.defaultProps = {
  apiKeysData: [],
};

ApiKeysTable.propTypes = {
  apiKeysData: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      name: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  organizationUuid: PropTypes.string.isRequired,
};

export default ApiKeysTable;

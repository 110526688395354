import {
  useCallback,
  useState,
 } from 'react';
import {
  useDispatch,
} from 'react-redux';
import {
  useParams,
} from 'react-router-dom';

import {
  Publish as PublishIcon,
} from '@mui/icons-material';

import {
  setSnackbarSuccess,
} from 'store/actions/global';
import {
  EXPORT_ADVOCATES_SUCCESS_MESSAGE,
  exportAdvocates,
} from 'store/actions/crm';

import {
  LinkButton,
} from 'common/components';

import { useCopyToClipboard } from 'utils/hooks';

import TopCard from 'common/containers/TopCard';

import {
  StyledCode,
} from 'globalStyles';

import WidgetDialog from '../../../ActionCenterDetails/components/WidgetDialog';

const {
  REACT_APP_LANDING_PAGE_URL,
  REACT_APP_LIST_WIDGET_URL,
} = process.env;

const CampaignsTopCard = () => {
  const dispatch = useDispatch();
  const [copy] = useCopyToClipboard();

  const [
    isWidgetModalOpen,
    setIsWidgetModalOpen,
  ] = useState(false);

  const {
    campaignUuid,
    organizationUuid,
  } = useParams();

  const handleSnackbarOpen = () => {
    dispatch(exportAdvocates(organizationUuid))
    .then(() => dispatch(setSnackbarSuccess({ message: EXPORT_ADVOCATES_SUCCESS_MESSAGE })));
  };

  const topCardData = useCallback(
    () => {
      const actionButtons = [
        {
          color: 'grey',
          name: 'List Widget',
          onClick: () => setIsWidgetModalOpen(true),
        },
        {
          color: 'grey',
          name: 'Export Advocates',
          onClick: handleSnackbarOpen,
          startIcon: <PublishIcon />,
        },
      ];

      return ({
        actionButtons,
        backButtonLink: `/${organizationUuid}/campaigns/${campaignUuid}`,
        restActions: (
          <LinkButton
            color="primary"
            name="New Campaign"
            to={`/${organizationUuid}/create-action-center`}
            variant="newItem"
          />
        ),
        title: 'Advocacy Campaigns',
      });
    },
    []
  );

  const widgetCode = `
    <div
      data-org-id="${organizationUuid}"
      data-domain="${REACT_APP_LANDING_PAGE_URL || 'https://mstr.app'}"
      data-height="500"
      data-width="455"
      id="muster-list"
    >
      <script src="${REACT_APP_LIST_WIDGET_URL || 'https://widgets.muster.com/v2/list-action-centers.js'}"></script>
    </div>
  `;

  const handleCopyToClipboard = () => {
    copy(widgetCode, 'textarea', 'Code');
  };

  return (
    <>
      <TopCard {...topCardData()} />
      <WidgetDialog
        isDialogOpen={isWidgetModalOpen}
        handleDialogClose={() => setIsWidgetModalOpen(false)}
        title="Embed a list of live action centers"
        handleCopyToClipboard={handleCopyToClipboard}
      >
        <>
          <StyledCode>
            {widgetCode}
          </StyledCode>
          <p>
            The code above can be embedded into your own website to list
            and link to your live action centers. They will be titled
            using the main headline.
          </p>
        </>
      </WidgetDialog>
    </>
  );
};

export default CampaignsTopCard;

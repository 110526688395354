import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MaterialTable, { MTableCell } from '@material-table/core';
import {
  Button,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {
  InfoOutlined as InfoOutlinedIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';

import { colors } from 'themes';

import { TableIcon } from 'common/components';
import { actionTakenMessageStatus } from 'utils/constants';
import { isInPreviousDays } from 'utils/helpers';
import ActionTakenModal from '../ActionTakenModal';

const ActionsTakenFiltersWrapper = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 90px;
  border-bottom: 0;
  background-color: ${colors.grayBackground};
`;

const ActionsTakenSelect = styled(TextField)`
  width: 240px;
  margin-left: 24px;
`;

const ActionRequiredButton = styled(Button)`
  background-color: ${colors.yellowWarning};

  &:hover {
    background-color: rgb(132 105 1);
  }
`;

const ActionsTakenTable = ({
  actionCenterActions,
  isActionCenterActionsFetching,
  showMessageApproval,
}) => {
  const {
    flaggedMessage,
    pendingMessage,
    quarantinedMessage,
   } = actionTakenMessageStatus;
  const [
    activeActionsTaken,
    setActiveActionsTaken,
  ] = useState([]);
  const [
    contactChannel,
    setContactChannel,
  ] = useState('All');
  const [
    datePeriod,
    setDatePeriod,
  ] = useState('All');
  const [
    action,
    setAction,
  ] = useState(null);

  const renderViewDetailsButton = rowData => (
    <Button
      onClick={() => setAction({
        messageStatus: rowData.messageStatus,
        type: rowData.type,
        uuid: rowData.uuid,
      })}
      disableElevation
      startIcon={<InfoOutlinedIcon />}
      variant="contained"
    >
      View Details
    </Button>
  );

  const renderActionButton = rowData => ([
    pendingMessage,
    flaggedMessage,
    quarantinedMessage,
  ]
    .some(status => rowData.messageStatus === status) ? (
      <ActionRequiredButton
        onClick={() => setAction({
          messageStatus: rowData.messageStatus,
          type: rowData.type,
          uuid: rowData.uuid,
        })}
        disableElevation
        startIcon={<WarningIcon />}
        variant="contained"
      >
        Action required
      </ActionRequiredButton>
    ) : (
      renderViewDetailsButton(rowData)
    ));

  const actionsTakenColumns = [
    {
      field: 'firstName',
      title: 'First name',
    },
    {
      field: 'lastName',
      title: 'Last name',
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'createdAt',
      title: 'Date',
      type: 'date',
    },
    {
      cellStyle: { fontSize: '26px' },
      field: 'type',
      render: rowData => (rowData.type === 'email' ? '📧' : '☎️'),
      title: 'Channel',
    },
    {
      field: 'isCustomized',
      render: rowData => (
        <TableIcon
          boolean={!!rowData.isCustomized}
          negativeIcon={null}
        />
      ),
      title: 'Custom?',
    },
    {
      field: 'messageStatus',
      render: rowData => (showMessageApproval ?
        renderActionButton(rowData) :
        renderViewDetailsButton(rowData)),
      title: '',
    },
  ];

  useEffect(() => {
    let filteredActionsTaken = actionCenterActions;

    if (contactChannel !== 'All') {
      filteredActionsTaken = filteredActionsTaken.filter(
        ({ type }) => (
          type.toLowerCase() === contactChannel.toLowerCase()
        )
      );
    }

    if (datePeriod !== 'All') {
      filteredActionsTaken = filteredActionsTaken.filter(
        ({ createdAt }) => {
          if (datePeriod.toLowerCase() === 'last week') {
            return isInPreviousDays(createdAt, 7);
          }

          if (datePeriod.toLowerCase() === 'last month') {
            return isInPreviousDays(createdAt, 30);
          }

          return createdAt;
        }
      );
    }

    setActiveActionsTaken(filteredActionsTaken);
  }, [
    actionCenterActions,
    contactChannel,
    datePeriod,
  ]);

  const handleContactChannelChange = event => setContactChannel(event.target.value);
  const handleDatePeriodChange = event => setDatePeriod(event.target.value);

  return (
    <>
      <ActionsTakenFiltersWrapper
        elevation={0}
        variant="outlined"
        square
      >
        <ActionsTakenSelect
          onChange={event => handleContactChannelChange(event)}
          label="Communication channel"
          select
          value={contactChannel}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="phone">Phone call</MenuItem>
        </ActionsTakenSelect>
        <ActionsTakenSelect
          onChange={event => handleDatePeriodChange(event)}
          label="Date"
          select
          value={datePeriod}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Last week">Last week</MenuItem>
          <MenuItem value="Last month">Last month</MenuItem>
        </ActionsTakenSelect>
      </ActionsTakenFiltersWrapper>
      <MaterialTable
        columns={actionsTakenColumns}
        components={{
          Cell: props => {
            /* eslint-disable react/prop-types */
            const {
              columnDef: {
                field,
              },
              value,
            } = props;

            if (field === 'date') {
              return (
                <MTableCell
                  {...props}
                  value={new Date(value).toLocaleDateString('us-US')}
                />
              );
            }

            return (
              <MTableCell
                {...props}
              />
            );
            /* eslint-enable react/prop-types */
          },
          Container: props => (
            <Paper
              {...props}
              elevation={0}
              square
              variant="outlined"
            />
          ),
        }}
        data={activeActionsTaken}
        isLoading={isActionCenterActionsFetching}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: colors.tableHeaderGray,
            color: colors.white,
          },
          selection: false,
          showTitle: false,
        }}
      />
      <ActionTakenModal
        action={action}
        setAction={setAction}
        showMessageApproval={showMessageApproval}
      />
    </>
  );
};

ActionsTakenTable.propTypes = {
  actionCenterActions: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  isActionCenterActionsFetching: PropTypes.bool.isRequired,
  showMessageApproval: PropTypes.bool.isRequired,
};

export default ActionsTakenTable;

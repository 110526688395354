import {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
 } from 'react';
 import {
  useDispatch,
} from 'react-redux';
import {
  Link as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import MaterialTable, { MTableBody } from '@material-table/core';
import useResizeObserver from '@react-hook/resize-observer';

import {
  Box,
  Chip,
  CircularProgress,
  Link,
} from '@mui/material';
import {
  ArrowForward as ArrowForwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import {
  fetchSsrCampaigns,
} from 'store/actions/campaigns';

import { Popover } from 'common/components';

import { CardWrapper } from 'globalStyles';
import {
  nameOfPublishStatus,
  useQuery,
} from 'utils/helpers';
import { colors } from 'themes';

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const chipColor = value => {
  switch (value) {
    case 1:
      return 'grey';
    case 2:
      return 'green';
    case 3:
      return 'error';
    case 4:
      return 'blue';
    default:
      return null;
  }
};

const useSize = target => {
  const [
    size,
    setSize,
  ] = useState();

  useLayoutEffect(() => {
    if (target) {
      setSize(target.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, entry => setSize(entry.contentRect));

  return size;
};

const isEven = number => number % 2 === 0;

const CampaignsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const searchText = query.get('searchValue');
  const tableRef = useRef(null);

  const {
    organizationUuid,
  } = useParams();

  const [
    campaignsData,
    setCampaignsData,
  ] = useState({
    next: '',
    page: 0,
    previous: '',
    results: [],
  });

  const [
    isCampaignsFetching,
    setIsCampaignsFetching,
  ] = useState(true);

  const renderLoader = () => (
    <Box
      position="relative"
      display="inline-flex"
    >
      <CircularProgress size={56} />
    </Box>
  );

  const handleDataFetch = async tableData => {
    const {
      search,
      pageSize,
      page: tablePage,
    } = tableData;
    let page = 0;
    const searchParam = search ? `&q=${search}` : '';
    let queryParams = `?organization=${organizationUuid}&page_size=${pageSize}${searchParam}`;

    // user is going forward or backward
    if (
      (campaignsData.page === tablePage - 1 || campaignsData.page === tablePage + 1) &&
      tablePage !== 0
    ) {
      const direction = campaignsData.page === tablePage - 1 ? 'next' : 'previous';

      queryParams = `?${campaignsData[direction].split('?')[1]}`;
      // page number attribute changes only when user goes forward or backward
      page = tablePage;
    }

    const campaigns = await dispatch(fetchSsrCampaigns(queryParams));

    setCampaignsData(prevValues => ({
      ...prevValues,
      ...campaigns,
      page,
    }));
    setIsCampaignsFetching(false);

    return ({
      data: campaigns.results,
      page,
      totalCount: campaigns.count,
    });
  };

  const campaignsColumns = useMemo(() => [
      {
        cellStyle: {
          fontWeight: '500',
        },
        field: 'name',
        render: rowData => (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/${organizationUuid}/campaigns/${rowData.uuid}`}
          >
            {rowData.name}
          </Link>
        ),
        title: 'Campaign / Action center',
        width: '25%',
      },
      {
        field: 'createdAt',
        render: rowData => new Date(rowData.createdAt).toLocaleDateString('us-US'),
        title: 'Creation Date',
      },
      {
        field: '',
        id: 'status',
        title: 'Status',
      },
      {
        field: 'statistics.advocates.totalCount',
        render: rowData => (
          <CellWrapper>
            {rowData.statistics?.advocates.totalCount}
            {(
              !!rowData.statistics?.advocates.last7DaysCount ||
              !!rowData.statistics?.advocates.last30DaysCount
            ) && (
              <Popover
                shouldDisplayPopover
                horizontalPosition="center"
                text={
                  `7 days: +${rowData.statistics?.advocates.last7DaysCount}
                  30 days: +${rowData.statistics?.advocates.last30DaysCount}`
                }
              >
                <IconButton size="small">
                  <ArrowUpwardIcon />
                </IconButton>
              </Popover>
            )}
          </CellWrapper>
        ),
        title: 'Advocates',
      },
      {
        field: 'statistics.emails.totalCount',
        title: 'Emails',
      },
      {
        field: 'statistics.signatures.totalCount',
        title: 'Signatures',
      },
      {
        field: 'statistics.phoneCalls.totalCount',
        title: 'Phone Calls',
      },
      {
        align: 'right',
        field: '',
        render: rowData => (
          <IconButton
            onClick={() => history.push(`/${organizationUuid}/campaigns/${rowData.uuid}/`)}
          >
            <ArrowForwardIcon />
          </IconButton>
        ),
        title: '',
      },
    ], [organizationUuid]);

  const handleRenderStatus = status => (
    <Chip
      label={nameOfPublishStatus(status)}
      size="medium"
      color={chipColor(status)}
    />
  );

  const materialTable = document.getElementById('campaign-table-wrapper');
  const cells = materialTable?.getElementsByTagName('th');

  const nameSize = useSize(cells?.[1]);

  return (
    <CardWrapper id="campaign-table-wrapper">
      <MaterialTable
        tableRef={tableRef}
        key="key"
        columns={campaignsColumns}
        detailPanel={({
          rowData,
        }) => (
          <Table sx={{
              backgroundColor: isEven(rowData.tableData.id) ? '#F8F8F8' : '#FFF',
              tableLayout: 'fixed',
            }}
          >
            <TableBody>
              {rowData?.actionCenters.map(actionCenter => (
                <TableRow key={`${actionCenter.name}-${actionCenter.publishStatus}`}>
                  <TableCell
                    style={{
                      borderBottom: 'none',
                    }}
                    width="42px"
                  />
                  <TableCell
                    {...(nameSize?.width && { width: `${nameSize.width + 32}px` })}
                    style={{
                      paddingLeft: '32px',
                    }}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`/${organizationUuid}/campaigns/${rowData.uuid}/action-centers/${actionCenter.uuid}?tab=0`}
                    >
                      {actionCenter.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {new Date(actionCenter.createdAt).toLocaleDateString('us-US')}
                  </TableCell>
                  <TableCell>
                    {handleRenderStatus(actionCenter.publishStatus)}
                  </TableCell>
                  <TableCell>
                    <CellWrapper>
                      {actionCenter.statistics?.advocates.totalCount}
                      {(
                        !!actionCenter.statistics?.advocates.last7DaysCount ||
                        !!actionCenter.statistics?.advocates.last30DaysCount
                      ) && (
                        <Popover
                          shouldDisplayPopover
                          horizontalPosition="center"
                          text={
                            `7 days: +${actionCenter.statistics?.advocates.last7DaysCount}
                            30 days: +${actionCenter.statistics?.advocates.last30DaysCount}`
                          }
                        >
                          <IconButton size="small">
                            <ArrowUpwardIcon />
                          </IconButton>
                        </Popover>
                      )}
                    </CellWrapper>
                  </TableCell>
                  <TableCell>{actionCenter.statistics?.emails.totalCount}</TableCell>
                  <TableCell>{actionCenter.statistics?.signatures.totalCount}</TableCell>
                  <TableCell>{actionCenter.statistics?.phoneCalls.totalCount}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label={actionCenter.name}
                      onClick={() => history.push(`/${organizationUuid}/campaigns/${rowData.uuid}/action-centers/${actionCenter.uuid}?tab=0`)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        components={{
          Body: bodyProps => {
              const {
                renderData,
                detailPanel,
              } = bodyProps;
              const EXPAND_DETAIL_PANEL_COUNT = 3;

              renderData?.forEach((d, i) => {
                if (renderData[i].tableData.index < EXPAND_DETAIL_PANEL_COUNT) {
                  if (!Object.prototype.hasOwnProperty.call(renderData[i].tableData, 'showDetailPanel')) {
                    renderData[i].tableData.showDetailPanel = detailPanel;
                  }
                }
              });

              const newProps = {
                ...bodyProps,
                renderData,
              };

              return <MTableBody {...newProps} />;
            },
        }}
        data={handleDataFetch}
        localization={{
          body: {
            emptyDataSourceMessage: (isCampaignsFetching) ?
              renderLoader() :
              'No records to display',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            color: colors.osloGray,
          },
          maxColumnSort: 0,
          pageSize: 20,
          pageSizeOptions: [
            5,
            20,
            50,
            100,
          ],
          rowStyle: (_, rowNumber) => ({
            backgroundColor: isEven(rowNumber) ? '#F8F8F8' : '#FFF',
            fontSize: '14px',
          }),
          searchDebounceDelay: 400,
          searchFieldAlignment: 'left',
          searchText,
          showFirstLastPageButtons: false,
          showTitle: false,
        }}
        style={{
          boxShadow: 'none',
        }}
      />
    </CardWrapper>
  );
};

CampaignsTable.propTypes = {
};

export default CampaignsTable;
